<template>
  <div class="app">
    <header>
      {{ $store.state.title }}
    </header>
    <main>
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
import router from "@/router";
import { mapMutations } from "vuex";
export default {
  methods: {
    ...mapMutations(["setTitle"]),
  },
};
</script>

<style lang="less" scoped>
.app,
main {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}
.app {
  display: flex;
  flex-direction: column;
}
main {
  flex-grow: 1;
  background-size: cover;
  background-color: #f7f9fa;
}
header {
  z-index: 500;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background-color: #e43a21;
  color: #fff;
}
footer {
  height: 50px;
}
.tabbar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-around;
  background-color: #fff;
  padding: 15px;
  box-sizing: border-box;
}

.iconfont {
  font-size: 20px !important;
  color: #000;
}
.icon-xiangzuo {
  position: absolute;
  left: 10px;
}
.tabbar p {
  font-size: 16px;
  color: #666;
}
.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.icon-jiahao {
  position: absolute;
  right: 20px;
  color: #fff;
}
</style>
