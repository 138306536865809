import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/font/font_4337957_tifrxer0uze/iconfont.css';
import './assets/font/font_4436858_zjtl8a0pskr/iconfont.css';
import './assets/global.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import BaiduMap from 'vue-baidu-map';
import Vant from 'vant';
import 'vant/lib/index.css';
import {Toast} from 'vant';
import CropMapTutorial from '@/views/common/components/Tutorial/cropMap/index.vue';
import ClaimMapTutorial from '@/views/common/components/Tutorial/claimMap/index.vue';

Vue.use(Toast);
//index.vue
Vue.use(Vant);
Vue.use(ElementUI);
Vue.config.productionTip = false;
// 全局注册组件
Vue.component('CropMapTutorial', CropMapTutorial);
Vue.component('ClaimMapTutorial', ClaimMapTutorial);

Vue.use(BaiduMap, {
  ak: 'BOt3qCIcLpYORscXlddV4IFBfCG3e9Z7', //  在此输入你自己的百度地图ak
});
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
