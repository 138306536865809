<template>
    <!-- <div class="block">
        <span class="demonstration">默认 Hover 指示器触发</span>
        <el-carousel height="150px">
            <el-carousel-item v-for="item in 4" :key="item">
                <h3 class="small">{{ item }}</h3>
            </el-carousel-item>
        </el-carousel>
    </div> -->
    <div class="block">
        <div class="demonstration"> <span></span></div>

        <el-carousel trigger="click" height="300px" :autoplay="false">
            <!-- <el-carousel-item v-for="item in 4" :key="item"> -->
            <el-carousel-item v-for="item, index in urls" :key="index">
                <el-image style="width:100%; height: 100%" :src="item.url" fit="fit"></el-image>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>
<script>
import Tutorrial from '@/assets/tutorial/ClaimTutorial.png'
export default {
    name: 'Tutorial',
    props: {
        type: {
            type: String,
            default: 'default'
        }
    },
    data() {
        return {
            urls: [{
                url: Tutorrial
            }]
        }
    },
    computed: {
        buttonType() {
            return `base-button--${this.type}`;
        }
    },
    methods: {
        handleClick(event) {
            this.$emit('click', event);
        }
    }
};
</script>
<style>
.demonstration {
    margin: auto;
    text-align: center;

}

.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
}
</style>