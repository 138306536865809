import Vue from 'vue';
import VueRouter from 'vue-router';
import layout from '../layout/index.vue';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/cropMap',
  },

  {
    path: '/collection-detail',
    name: 'collection-detail',
    component: layout,
    children: [
      {
        path: '/collection/detail',
        component: () => import('../views/collection/details/index.vue'),
      },
    ],
  },
  {
    path: '/map',
    name: 'map',
    component: () => import('../views/map/index.vue'),
  },
  {
    path: '/cropMap',
    name: 'cropMap',
    component: () => import('../views/cropMap/index.vue'),
  },
  {
    path: '/breedMap',
    name: 'breedMap',
    component: () => import('../views/breedMap/index.vue'),
  },
  {
    path: '/cornOne',
    name: 'cornOne',
    component: () => import('../views/collectForm/cornOne.vue'),
  },
  {
    path: '/riceOne',
    name: 'riceOne',
    component: () => import('../views/collectForm/riceOne.vue'),
  },
  {
    path: '/navi',
    name: 'navi',
    component: () => import('../views/navi/navi.vue'),
  },
  {
    path: '/lipei',
    name: 'lipei',
    component: () => import('../views/lipei/lipeimap/lipeimap.vue'),
  },
  {
    path: '/snapshot',
    name: 'snapshot',
    component: () => import('../views/snapshot/index.vue'),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
